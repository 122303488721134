import React, { useEffect, useState } from 'react'
import TabsHeader from '../../components/Headers/TabsHeader'
import DateSlider from '../../components/Dates/DateSlider'
import { useQuery } from 'react-query'
import { api } from '../../lib/api'
import { sessionType, useSessions } from '../../data/sessions'
import moment from 'moment'
import { sortStartTimes } from '../../lib/sort'
import { useUser } from '../../data/user'
import Session from '../../components/Cards/Session'
import { Skeleton } from '../../components/ui/skeleton'
import { SessionSkeleton } from '../Schedule/Index'
import { Button } from '../../components/ui/button'
import { Link } from 'react-router-dom'

const MySchedule = () => {
  const { sessions, loading } = useSessions();
  const { user } = useUser();
  const [ selectedDate, setSelectedDate ] = useState(moment().date());
  const [ currentSessions, setCurrentSessions ] = useState<sessionType[]>([]);
  const [ startTime, setStartTimes ] = useState<string[]>([]);
  const [ dates, setDates ] = useState<Date[]>([])

  useEffect(() => {
    if(sessions){
      const mysessions = sessions.filter(({id}) => user?.sessionId.includes(id));
      const filterSessions = mysessions.filter(({date}) => moment(date).date() === selectedDate)
      setCurrentSessions(filterSessions);

      const sortedDates = sessions.sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime())
      .map(({date}) => date);
      setDates([...new Set(sortedDates)]);

      const times = filterSessions.map(({startTime}) => startTime)
      .sort(sortStartTimes);
      setStartTimes([...new Set(times)]);      
    }
  }, [selectedDate, sessions])

  return (
    <section>
      <TabsHeader
      title='My Schedule'/>


      <section className='py- space-y-5'>
        <DateSlider dates={dates} selectedDate={selectedDate} setSelectedDate={setSelectedDate}/>

        {
          startTime.map(time => (
            <div className='px-3 space-y-4'>
              <p className='py-4 border-b'>{time}</p>

              <div className='flex flex-col gap-4'>
                {
                  currentSessions.filter(({startTime, date}) => startTime === time).map(session => (
                    <Session
                      id={session.id}
                      key={session.id}
                      href={`/dashboard/session/${session.id}`}
                      title={session.title}
                      description={session.description}
                      color={session.color}
                      sponsor={session.sponsor}
                      venue={session.venue}
                      />
                  ))
                }
              </div>
            </div>            
          ))
        }
        {
          (currentSessions.length === 0 && !loading) && (
            <div className='px-3 my-10 py-5 border-t flex-col flex items-center space-y-5'>
              <p className='text-lg'>You've no session today!</p>

              <Link to={"/dashboard/schedule"}>
                <Button className='shadow-xl'>View Sessions</Button>
              </Link>
            </div>
          )
        }

        {
          loading && (
            <div className='px-3 space-y-4 py-5'>
              <Skeleton className='h-4 w-8'/>

              <div className='flex flex-col gap-4'>
                {
                  Array(5).fill("").map(session => (
                    <SessionSkeleton/>
                  ))
                }
              </div>
            </div>
          )
        }

      </section>

    </section>
  )
}

export default MySchedule