import { ChevronLeft } from 'lucide-react'
import React from 'react'
import { useNavigate, useNavigation } from 'react-router-dom'

const BackButton = () => {
  const navigate = useNavigate()
  return (
    <div onClick={() => navigate(-1)} className='flex items-center gap-3 text-white'>
        <div className='h-8 w-8 p-2 bg-blue-200 rounded-full'>
            <ChevronLeft className='h-full w-full text-main'/>
        </div>
        <span className=''>Back</span>
    </div>
  )
}

export default BackButton