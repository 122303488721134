import React from 'react'
import { speakerType } from '../../data/speakers'
import { url } from '../../lib/api';

interface Props{
    name: string;
    title: string | null;
    bio: string | null;
    image: string | null;
}

const Speaker = ({name, title, bio, image} : Props) => {
  return (
    <div className='rounded-2xl bg-white flex flex-col min-h-40'>
        <div className='col-span-3'>
            <img src={`${image}`} alt="speaker" className='h-72 w-full rounded-t-2xl object-cover'/>
        </div>
        <div className='col-span-4 flex flex-col gap-4 p-4'>
            <div className='flex flex-col'>
                <p className='font-semibold text-xl'>{name}</p>
                <span className='capitalize'>{title}</span>                
            </div>

            <span className=''>{bio?.slice(0, 90)}...</span>
        </div>
    </div>
  )
}

export default Speaker