import React from 'react'
import TabsHeader from '../../components/Headers/TabsHeader'
import { attendees, useAttendees } from '../../data/attendees'
import Attendee from '../../components/Cards/Attendee'
import Search from '../../components/Cards/Search'
import { Link } from 'react-router-dom'

const Attendees = () => {
  const { attendees } = useAttendees();

  return (
    <section className='bg-gray-100 relative font-figtree pb-10 space-y-8 min-h-screen'>
      <div className='sticky top-0 '>
        <TabsHeader
        title='Attendees'
        back
        extend/>

        <div className='px-5 -mt-8'>
          <Search/>
        </div>        
      </div>


      <div className='px-5 flex flex-col gap-4'>
        {
          attendees.map((attendee) => (
            // <Link to={`/dashboard/attendees/${attendee.id}`}>
            <Attendee
              firstName={attendee.firstName}
              lastName={attendee.lastName}
              email={attendee.email}/>
            // </Link>
          ))
        }
      </div>
    </section>

  )
}

export default Attendees