import React from 'react'
import { useParams } from 'react-router-dom';
import { useSponsor } from '../../../data/sponsors';
import SubHeader from '../../../components/Headers/SubHeader';
import { image } from '../../../lib/image';

const Sponsor = () => {
  const { sponsorId } = useParams();
  const { sponsors } = useSponsor();

  const sponsor = sponsors.find(({id}) => id === sponsorId);

  if(!sponsor) throw new Error("Sponsor not found");
  
  console.log(sponsor);

  return (
    <section className='font-figtree bg-gray-200 min-h-screen'>
      <SubHeader
       title='Sponsors'/>

       <div className='flex justify-center -mt-16'>
        <img src={image(sponsor.logo)} alt="logo" className='h-36 w-36 bg-white rounded-lg p-5 object-contain' />
       </div>

       <section className='px-5 py-10 space-y-5'>
          <p className='text-center text-2xl font-semibold'>{sponsor.name}</p>
          <p>{sponsor.description}</p>

        {
          sponsor.website && (
            <div className='space-y-2'>
              <p className='font-semibold text-lg'>Website</p>
              <a href={sponsor.website} className='underline'>{sponsor.website}</a>
            </div>
          )
        }
        {
          sponsor.mobile && (
            <div className='space-y-2'>
              <p className='font-semibold text-lg'>Contact</p>
              <a href={sponsor.mobile} className='underline'>{sponsor.mobile}</a>
            </div>
          )
        }
       </section>
    </section>
  )
}

export default Sponsor