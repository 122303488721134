import React from 'react'
import { 
    Carousel,
    CarouselContent,
    CarouselItem
} from '../ui/carousel';
import { board } from '../../data/board';
import Autoplay from 'embla-carousel-autoplay';

const Board = () => {
    const plugin = React.useRef(
        Autoplay({ delay: 2000, stopOnInteraction: true })
      )
     
  return (
    <Carousel
    plugins={[plugin.current] as any}
    className=''>
        <CarouselContent className='px-3'>
            {
                board.map((board, index) => (
                    <CarouselItem key={index} className='basis-0.8/2'>
                        <div className='flex flex-col justify-center gap-1 items-center max-w-48'>
                            <img src={board.image} alt="" className='object-contain rounded-full h-32' />
                            <p className='text-lg whitespace-nowrap font-semibold'>{board.name}</p>
                            <p className='text-center'>{board.position}</p>
                        </div>
                    </CarouselItem>
                ))
            }
        </CarouselContent>
    </Carousel>
  )
}

export default Board