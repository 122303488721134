import { create } from "zustand";

export interface attendeesType  {
  id: string;
  email: string;
  firstName: string;
  lastName: string;
}

interface attendeesStore {
    attendees : attendeesType[];
    updateAttendees : (data: attendeesType[]) => void;
}

export const useAttendees = create<attendeesStore>()(set => ({
    attendees : [],
    updateAttendees : (data:attendeesType[]) => set(state => ({attendees : data}))
}));


export const attendees = [
    { 
     firstName: "John",
     lastName: "Doe",
     email: "john.doe@example.com",
     mobile: "123-456-7890",
     image: "john_doe.jpg" 
    },
    { 
     firstName: "Jane",
     lastName: "Smith",
     email: "jane.smith@example.com",
     mobile: "987-654-3210",
     image: "jane_smith.jpg" 
    },
    { 
     firstName: "Alice",
     lastName: "Johnson",
     email: "alice.johnson@example.com",
     image: "alice_johnson.jpg" 
    },
    { 
     firstName: "Bob",
     lastName: "Williams",
     email: "bob.williams@example.com",
     image: "bob_williams.jpg" 
    },
    { 
     firstName: "Eva",
     lastName: "Anderson",
     email: "eva.anderson@example.com",
     mobile: "555-123-4567",
     image: "eva_anderson.jpg" 
    },
    { 
     firstName: "Charlie",
     lastName: "Brown",
     email: "charlie.brown@example.com",
     image: "charlie_brown.jpg" 
    },
    { 
     firstName: "Sophia",
     lastName: "Clark",
     email: "sophia.clark@example.com",
     mobile: "111-222-3333",
     image: "sophia_clark.jpg" 
    },
    { 
     firstName: "David",
     lastName: "Miller",
     email: "david.miller@example.com",
     image: "david_miller.jpg" 
    },
    { 
     firstName: "Emma",
     lastName: "Wilson",
     email: "emma.wilson@example.com",
     mobile: "777-888-9999",
     image: "emma_wilson.jpg" 
    },
    { 
     firstName: "Ryan",
     lastName: "Thomas",
     email: "ryan.thomas@example.com",
     image: "ryan_thomas.jpg" 
    },
    { 
     firstName: "Olivia",
     lastName: "Turner",
     email: "olivia.turner@example.com",
     mobile: "333-444-5555",
     image: "olivia_turner.jpg" 
    },
    { 
     firstName: "Michael",
     lastName: "Davis",
     email: "michael.davis@example.com",
     image: "michael_davis.jpg" 
    },
    { 
     firstName: "Sophie",
     lastName: "White",
     email: "sophie.white@example.com",
     mobile: "666-777-8888",
     image: "sophie_white.jpg" 
    },
    { 
     firstName: "Daniel",
     lastName: "Baker",
     email: "daniel.baker@example.com",
     image: "daniel_baker.jpg" 
    },
    { 
     firstName: "Grace",
     lastName: "Evans",
     email: "grace.evans@example.com",
     mobile: "999-000-1111",
     image: "grace_evans.jpg" 
    },
    { 
     firstName: "William",
     lastName: "Moore",
     email: "william.moore@example.com",
     image: "william_moore.jpg" 
    },
    { 
     firstName: "Ava",
     lastName: "Hill",
     email: "ava.hill@example.com",
     mobile: "444-555-6666",
     image: "ava_hill.jpg" 
    },
    { 
     firstName: "Liam",
     lastName: "Ross",
     email: "liam.ross@example.com",
     image: "liam_ross.jpg" 
    },
    { 
     firstName: "Mia",
     lastName: "Cole",
     email: "mia.cole@example.com",
     mobile: "222-333-4444",
     image: "mia_cole.jpg" 
    },
  ];