import React from 'react'
import { 
    Carousel,
    CarouselContent,
    CarouselItem
} from '../ui/carousel'
import { useSponsor } from '../../data/sponsors'
import Sponsor from '../Cards/Sponsor'
import { Link } from 'react-router-dom'
import { api, url } from '../../lib/api'
import { image } from '../../lib/image'

const SponsorSlide = () => {
    const { sponsors } = useSponsor();

  return (
    <Carousel className=''>
        <CarouselContent className='px-3'>
            {
                sponsors.map((sponsor) => (
                    <CarouselItem key={sponsor.id} className='basis-0.8/2'>
                        <Link to={`/dashboard/sponsors/${sponsor.id}`}>
                            <Sponsor
                                src={image(sponsor.logo)}
                                name={sponsor.name}
                                role={sponsor.role}/>
                        </Link>
                    </CarouselItem>
                ))
            }
        </CarouselContent>
    </Carousel>
  )
}

export default SponsorSlide