import React from 'react'
import { FormControl, FormField, FormItem, FormLabel, FormMessage } from '../ui/form'
import { Input } from '../ui/input'
import { Textarea } from '../ui/textarea';

interface Props {
    control : any;
    name : string;
    label : string;
    placeholder ?: string;
    type ?: string;
    textarea ?: boolean;
}

const CustomInput = ({control, name, label, placeholder, type = "text", textarea} : Props) => {
  return (
    <FormField
    control={control}
    name={name}
    render={({ field }) => (
      <FormItem>
        <FormLabel>{label}</FormLabel>
        <FormControl>
          {
            textarea ? <Textarea className={"bg-blue-100/80 py-6 border-0"} placeholder={placeholder} {...field} /> : <Input className={"bg-blue-100/80 py-6 border-0"} placeholder={placeholder} type={type} {...field} />
          }
        </FormControl>
        <FormMessage />
      </FormItem>
    )}
  />
  )
}

export default CustomInput