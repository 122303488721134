import moment from "moment";
import { create } from "zustand";

export interface sessionType {
  id: string;
  title: string;
  startTime: string;
  endTime: string;
  date: Date;
  description: string;
  color: string;
  image: string | null;
  speakerId: string[];
  venue : string;
  sponsor ?: string;
  moderator ?: string;
  createdAt: Date;
}

interface sessionStore {
    sessions : sessionType[];
    loading : boolean;
    currentDate : number;
    updateSessions : (data: sessionType[]) => void;
    updateLoading : (loading:boolean) => void;
    setCurrentDate : (date:number) => void;
}

export const useSessions = create<sessionStore>()(set => ({
    sessions : [],
    loading : true,
    currentDate : moment("2024-02-01").date(),
    updateSessions : (data:sessionType[]) => set(state => ({sessions : data})),
    updateLoading : (loading:boolean) => set(state => ({loading : loading})),
    setCurrentDate: (date:number) => set(state => ({currentDate : date})) 
}));


export const sessions = [
    {
      id: "1",
      title: "Opening Keynote",
      description: "Join us for the grand opening keynote session. Exciting announcements and insights await!",
      startTime: "09:00 AM",
      endTime: "10:30 AM",
      date: "2024-01-05",
      color: "bg-blue-500",
      speakers: [
        {
          id: "speaker_1",
        },
        {
          id: "speaker_2",
        },
      ],
    },
    {
      id: "2",
      title: "Panel Discussion: Future Trends",
      description: "A panel of experts will discuss and share insights on emerging trends in the tech industry.",
      startTime: "11:00 AM",
      endTime: "12:30 PM",
      date: "2024-01-08",
      color: "bg-green-500",
      speakers: [
        {
          id: "speaker_3",
        },
        {
          id: "speaker_4",
        },
      ],
    },
    {
      id: "3",
      title: "Lunch Break",
      description: "Enjoy a delicious lunch and network with fellow attendees.",
      startTime: "12:30 PM",
      endTime: "01:30 PM",
      date: "2024-01-09",
      color: "bg-yellow-500",
      speakers: [],
    },
    {
      id: "4",
      title: "Workshop: Hands-on Coding",
      description: "Participate in an interactive coding workshop. Bring your laptop and get ready to code!",
      startTime: "02:00 PM",
      endTime: "03:30 PM",
      date: "2024-01-05",
      color: "bg-purple-500",
      speakers: [
        {
          id: "speaker_5",
        },
      ],
    },
    {
      id: "5",
      title: "Networking Session",
      description: "Connect with other attendees, speakers, and sponsors during our networking session.",
      startTime: "01:00 PM",
      endTime: "05:30 PM",
      date: "2024-01-06",
      color: "bg-orange-500",
      speakers: [],
    },
    {
      id: "6",
      title: "Closing Remarks",
      description: "Join us for the closing remarks and a recap of the day's highlights.",
      startTime: "06:00 PM",
      endTime: "07:00 PM",
      date: "2024-01-04",
      color: "bg-red-500",
      speakers: [
        {
          id: "speaker_6",
        },
      ],
    },
    {
      id: "7",
      title: "After-Event Social",
      description: "Wind down and socialize with fellow attendees and speakers at our post-event gathering.",
      startTime: "07:30 PM",
      endTime: "09:00 PM",
      date: "2024-01-05",
      color: "bg-indigo-500",
      speakers: [],
    },
    {
      id: "8",
      title: "After-Event Social",
      description: "Wind down and socialize with fellow attendees and speakers at our post-event gathering.",
      startTime: "07:30 PM",
      endTime: "09:00 PM",
      date: "2024-01-06",
      color: "bg-indigo-500",
      speakers: [],
    },
    {
      id: "9",
      title: "After-Event Social",
      description: "Wind down and socialize with fellow attendees and speakers at our post-event gathering.",
      startTime: "07:30 PM",
      endTime: "09:00 PM",
      date: "2024-01-07",
      color: "bg-indigo-500",
      speakers: [],
    },
  ];
  