import clsx from 'clsx';
import { CalendarCheck, CalendarHeart, HomeIcon, UserIcon } from 'lucide-react'
import React from 'react'
import { Link, useLocation } from 'react-router-dom';

const routes = [
    {
        path : "/dashboard",
        name : "Home",
        Icon : HomeIcon
    },
    {
        path : "/dashboard/schedule",
        name : "Schedule",
        Icon : CalendarCheck
    },
    {
        path : "/dashboard/my-schedule",
        name : "My Schedule",
        Icon : CalendarHeart
    },
    {
        path : "/dashboard/profile",
        name : "Profile",
        Icon : UserIcon
    }
]

const MenuTab = () => {
    const location = useLocation();
  
    return (
      <div className='bg-white border-t grid grid-cols-4 fixed bottom-0 w-full'>
          {
              routes.map(({ name, path, Icon }) => (
                <Link
                    key={name}
                    to={path}>
                    <div
                        className={clsx('flex flex-col items-center justify-center gap-1 py-3 font-light relative', {
                            'text-main': location.pathname === path
                        })}
                    >
                        <Icon className='h-6 w-6'/>
                        <span className={clsx('capitalize text-xs', {
                            'font-semibold' : location.pathname === path
                        })}>{name}</span>
                        <div className={clsx('py-1 bg-main w-2/4 rounded-t-full absolute bottom-0', {
                            'hidden': location.pathname !== path
                        })}/>
                    </div>
                </Link>
              ))
          }
      </div>
    );
  };

export default MenuTab