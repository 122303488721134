import React from 'react'
import Logo from '../Logo/Logo'
import BackButton from '../Nav/BackButton'
import { cn } from '../../lib/utils'

const TabsHeader = ({title, back, extend} : {title : string, back ?: boolean, extend ?: boolean}) => {
  return (
    <div className={cn('bg-main px-3 py-5 space-y-4', {
      'pt-10' : !back,
      'pb-20' : extend
    })}>
      {
        back && <BackButton/>
      }
      <div className='flex text-white items-end justify-between'>
        <p className='text-3xl font-semibold'>{title}</p>
        <Logo/>        
      </div>
    </div>
  )
}

export default TabsHeader