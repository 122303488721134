import React from 'react'
import TabsHeader from '../../components/Headers/TabsHeader'
import { useSpeakers } from '../../data/speakers'
import Speaker from '../../components/Cards/Speaker'
import { Link } from 'react-router-dom'
import { image } from '../../lib/image'

const Speakers = () => {
  const { speakers } = useSpeakers();

  console.log(speakers);
  return (
    <div className='bg-gray-100 min-h-screen font-figtree pb-10'>
      <TabsHeader
      title='Speakers'
      back
      extend/>

      <div className='px-5 -mt-8 flex flex-col gap-8'>
        {
          speakers.map((speaker) => (
            <Link to={`/dashboard/speakers/${speaker.id}`}>
              <Speaker 
                key={speaker.id}
                name={speaker.name}
                title={speaker.title}
                bio={speaker.bio}
                image={image(speaker.image)}/>
            </Link>
          ))
        }
      </div>
    </div>
  )
}

export default Speakers