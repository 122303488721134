import React from 'react'
import { Outlet } from 'react-router-dom'
import AdminNav from './navbar'
import Attendee from './Attendee'
import Notification from './Notification'
import Sponsor from './Sponsor'
import Sessions from './Sessions'
import Speaker from './speaker'

export const adminRoutes = [
  {
    path : "attendee",
    element : <Attendee/>
  },
  {
    path : "session",
    element : <Sessions/>
  },
  {
    path : "sponsor",
    element : <Sponsor/>
  },
  {
    path : "speaker",
    element : <Speaker/>
  },
  {
    path : "notification",
    element : <Notification/>
  }
];


const Admin = () => {
  return (
    <div className='font-figtree'>
      <AdminNav/>
      <div className='p-5 sm:p-20'>
        <Outlet/>        
      </div>
    </div>
  )
}

export default Admin