import React, { ChangeEvent, useState } from 'react'
import TabsHeader from '../../components/Headers/TabsHeader'
import { useMutation, useQuery } from 'react-query';
import { api, url } from '../../lib/api';
import { Skeleton } from '../../components/ui/skeleton';
import { useUser } from '../../data/user';
import { Button } from '../../components/ui/button';
import { Image, PictureInPicture } from 'lucide-react';
import { XMarkIcon } from '@heroicons/react/20/solid';
import { image as picture } from '../../lib/image'
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "../../components/ui/dialog";
import { ButtonLoading } from '../../components/Button/CustomButton';
import { DownloadIcon } from '@radix-ui/react-icons';

const Gallery = () => {
  const { token } = useUser();
  const [ image, setImage ] = useState<File | null>(null);
  const [ open, setOpen ] = useState(true);
  const [ active, setActive ] = useState<string | null>(null);

  const { isLoading, data = [], refetch } = useQuery({
    queryKey: ['gallery'],
    queryFn: async () => {
        const res = await api.get("/api/gallery", {
          headers : {
            Authorization : `Bearer ${token}`,
            "Content-Type": 'application/json'
          }
        });
        return res.data;
    },
    enabled: token !== null
  });

  const selectImage = (e:ChangeEvent<HTMLInputElement>) => {
    const { files } = e.target;

    if(files){
      setImage(files[0]);
    }
  }

  const { mutate, isLoading:uploadLoading } = useMutation({
    mutationFn: async (image: File) => {
      const formData = new FormData();
      formData.append('image', image);
      const response = await api.post('/api/gallery', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'Authorization': `Bearer ${token}`
        }
      });
      return response.data;
    },
    onSuccess: () => {
      refetch();
      setImage(null);
    }
  })

  return (
    <div className='bg-gray-100 min-h-screen font-figtree'>
      <div className=''>
        <TabsHeader
        title='Gallery'
        back extend/>
      </div>
      <div className='bg-white p-5 rounded-lg shadow m-5 z-50 -mt-8 space-y-4'>
          <p className='font-semibold text-sm'>Share Your WF CHRIE Moments</p>

          <div className='border relative text-xs'>
            <p className='flex items-center gap-3 p-2'><Image className='h-4 w-4 text-blue-500'/> Click here to select an image</p>
            <input onChange={selectImage} type="file" accept='.jpg,.png,.svg,.webp' name="image" className='absolute h-full w-full top-0 opacity-0 border' placeholder='Select an Image' />
          </div>
          {
            image && (
              <div className='relative'>
                <XMarkIcon className='h-8 w-8 absolute top-0 bg-white' onClick={() => setImage(null)}/>
                <img src={URL.createObjectURL(image)} alt="image" className='h-40 w-40 object-cover' />
              </div>
            )
          }

          {
            uploadLoading ? <ButtonLoading/> : <Button onClick={() => image ? mutate(image) : alert("Select an image first")}>Upload Image</Button>
          }
        </div>



      <div className='grid grid-cols-2 gap-2 p-5'>
        {
          data.map((gallery: { image:string, caption:string}, index:number) => (
            <img onClick={() => {setOpen(true); setActive(gallery.image)}} src={picture(gallery.image)} alt="image" className='rounded-2xl object-cover h-44 w-full border-4 border-white'/>
          ))
        }
        {
          isLoading && (
            Array(8).fill("").map(() => (
              <Skeleton className='h-44 w-full rounded-2xl'/>
            ))
          )
        }
      </div>

      {
        active && (
          <ViewImage
          open={open}
          setOpen={setOpen}
          image={active}/>
        )
      }
    </div>
  )
}

const ViewImage =  ({ open, setOpen, image } : { open: boolean, setOpen : any, image : string}) => {
  const downloadImage = async () => {
    try {
      // Fetch the image data as a blob
      const response = await fetch(picture(image));
      const blob = await response.blob();

      // Create a temporary link and set its attributes
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = 'wfchrie.jpg';

      // Trigger a click on the link to start the download
      document.body.appendChild(link);
      link.click();

      // Remove the link from the DOM
      document.body.removeChild(link);

    } catch (error) {
      console.error('Error downloading image:', error);
    }
  };

  return(
  <Dialog open={open} onOpenChange={setOpen}>
    <DialogContent className='p-5'>
        <DialogDescription>
          <img src={picture(image)} alt="" className='w-full' />
        </DialogDescription>
      <div className='flex gap-4'>
        {/* <a href={picture(image)} download="wfchrie.jpg"> */}
          <Button onClick={downloadImage} className='flex items-center gap-3'><DownloadIcon className='h-6 w-6'/> Download</Button>
        {/* </a> */}
        <DialogClose><Button variant={"destructive"}>Close</Button></DialogClose>
      </div>
    </DialogContent>
  </Dialog>
  )
}

export default Gallery