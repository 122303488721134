import React, { useEffect, useState } from 'react'
import { useQuery } from 'react-query'
import { Outlet, redirect, useNavigate } from 'react-router-dom'
import { api } from '../lib/api'
import { UserType, useUser } from '../data/user'
import { sessionType, useSessions } from '../data/sessions'
import { speakerType, useSpeakers } from '../data/speakers'
import { NotificationType, useNotifications } from '../data/notification'
import { attendeesType, useAttendees } from '../data/attendees'
import { sponsorType, useSponsor } from '../data/sponsors'
import { LocalNotifications } from '@capacitor/local-notifications'

const Dashboard = () => {
  const { updateUser } = useUser();
  const { updateSessions, updateLoading } = useSessions();
  const { updateSpeaker} = useSpeakers();
  const { updateNotification} = useNotifications();
  const { updateAttendees } = useAttendees();
  const { updateSponsor } = useSponsor();
  const { token } = useUser();
  const navigate = useNavigate();

  const user = useQuery({
    queryKey : [token],
    queryFn : async () => {
      const res = await api.get("/api/user", {
        headers : {
          Authorization : `Bearer ${token}`,
          "Content-Type": 'application/json'
        }
      });

      return res.data;
    },
    enabled : (token !== null),
    onSuccess: (data:UserType) => updateUser(data),
  });

  const sessions = useQuery({
    queryKey : ['sessions'],
    queryFn : async () => {
      const res = await api.get("/api/session", {
        headers : {
          Authorization : `Bearer ${token}`,
          "Content-Type": 'application/json'
        }
      });

      const sessions:sessionType[] = res.data
      return sessions
    },
    enabled : (token !== null),
    onSuccess: (data) => {
      updateSessions(data);
    },
    onSettled: () => updateLoading(false)
  });

  useQuery({
    queryKey : ['speakers'],
    queryFn : async () => {
      const res = await api.get("/api/speaker", {
        headers : {
          Authorization : `Bearer ${token}`,
          "Content-Type": 'application/json'
        }
      });

      const speakers:speakerType[] = res.data.data
      return speakers
    },
    enabled : (token !== null),
    onSuccess: (data) => updateSpeaker(data)
  });

  useQuery({
    queryKey : ['notifications'],
    queryFn : async () => {
      const res = await api.get("/api/notification", {
        headers : {
          Authorization : `Bearer ${token}`,
          "Content-Type": 'application/json'
        }
      });

      const notifications:NotificationType[] = res.data.data
      return notifications.reverse()
    },
    enabled : (token !== null),
    onSuccess: (data) => updateNotification(data)
  });

  useQuery({
    queryKey : ['attendees'],
    queryFn : async () => {
      const res = await api.get("/api/attendee", {
        headers : {
          Authorization : `Bearer ${token}`,
          "Content-Type": 'application/json'
        }
      });

      const notifications:attendeesType[] = res.data
      return notifications
    },
    enabled : (token !== null),
    onSuccess: (data) => updateAttendees(data)
  });

  useQuery({
    queryKey : ['sponsors'],
    queryFn : async () => {
      const res = await api.get("/api/sponsor", {
        headers : {
          Authorization : `Bearer ${token}`,
          "Content-Type": 'application/json'
        }
      });

      const notifications:sponsorType[] = res.data.data
      return notifications
    },
    enabled : (token !== null),
    onSuccess: (data) => updateSponsor(data)
  });

  const permission = async () => {
    const { display } = await LocalNotifications.checkPermissions();

    if(display !== "granted"){
      await LocalNotifications.requestPermissions()
    }

    await LocalNotifications.removeAllDeliveredNotifications();

    const welcomeMessage = localStorage.getItem("notify");
    if(!welcomeMessage){
      await LocalNotifications.schedule({
          notifications: [
            {
              title: 'Welcome To WF CHRIE Conference',
              body: `The conference will take place on February 1-3, 2024 in Denver, Colorado. Metropolitan State University will be our host.  It's already shaping up to be a fantastic event, and we look forward to seeing you all there.`,
              id: 1,
              schedule: { at: new Date(Date.now() + 5000) },
            },
          ],
      });
      
      localStorage.setItem("notify", "done");
    }
  }

  useEffect(() => {
    permission();

    const token = localStorage.getItem("token");
    !token && navigate("/auth/login");
  }, []);

  
  return (
    <Outlet/>
  )
}

export default Dashboard