import React from 'react'
import SubHeader from '../../components/Headers/SubHeader'
import { AwardIcon } from 'lucide-react'

const Awards = () => {
  return (
    <div>
        <SubHeader
        title='Awards'
        text='Conference awards'/>

        <div className='bg-white w-4/5 py-2 -mt-8 mx-auto font-figtree text-sm flex items-center justify-center gap-4 rounded-xl shadow'>
           <AwardIcon className='h-10 w-10 p-2 text-blue-500 rounded bg-blue-100'/> Sponsored by CSU East Bay HRT
        </div>


        <section className='px-3 py-10 space-y-7 font-figtree'>
            <div className='space-y-4 shadow p-4'>
                <div>
                    <p className='font-bold text-blue-900 text-lg'>BEST PAPER AWARD: FOR APPLIED RESEARCH TRACK</p>
                </div>
                <div>
                    <p className='text-sm'>
                        EFFECTS OF GREEN HRM PRACTICES ON EMPLOYEE PRO-ENVIRONMENTAL BEHAVIOR: THE ROLE
                        OF ETHICAL CLIMATE AND NEW ENVIRONMENTAL PARADIGM (Submission 7)
                        by Lenna Shulga (Associate Professor, University of Hawaii at Manoa) and Adiyukh Berbekova
                        (Assistant Professor, University of Hawaii at Manoa)
                    </p>
                </div>
            </div>
            <div className='space-y-4 shadow p-4'>
                <div>
                    <p className='font-bold text-blue-900 text-lg'>BEST PAPER AWARD: FOR EDUCATION TRACK</p>
                </div>
                <div>
                    <p className='text-sm'>
                    EXAMINING THE HOSPITALITY CURRICULUM FOR LAW AND ETHICS IN THE UNITED STATES
                    by Cho Tin Tun Kirkpatrick Nova Southeastern University and Colorado Mountain College and
                    Dr. Jean Hertzman (New Mexico State University)
                    </p>
                </div>
            </div>
            <div className='space-y-4 shadow p-4'>
                <div>
                    <p className='font-bold text-blue-900 text-lg'>BEST POSTER AWARD</p>
                </div>
                <div>
                    <p className='text-sm'>
                        To be determined by attendee vote
                    </p>
                </div>
            </div>
            <div className='space-y-4 shadow p-4'>
                <div>
                    <p className='font-bold text-blue-900 text-lg'>BEST REVIEWER AWARD</p>
                </div>
                <div>
                    <p className='text-sm'>
                        Jamie Levitt, California State University, Fresno
                    </p>
                </div>
            </div>
            <div className='space-y-4 shadow p-4'>
                <div>
                    <p className='font-bold text-blue-900 text-lg'>BEST SPEED PRESENTATION AWARD</p>
                </div>
                <div>
                    <p className='text-sm'>
                    BLACKS AT THE TOP: UNDERSTANDING BARRIERS AND FACILITATORS TO HOSPITALITY
                    LEADERSHIP (Submission 44)
                    by Del Gerard - California State University San Bernardino & Michelle Russen, California State
                    University San Bernardino
                    </p>
                </div>
            </div>
        </section>
    </div>
  )
}

export default Awards