import { create } from "zustand";
import { sessionType } from "./sessions";

export interface speakerType {
  id: string;
  name: string;
  email: string;
  title: string | null;
  bio: string | null;
  image: string | null;
  sessionId: string[];
  sessions : sessionType[];
}

interface speakerStore {
    speakers : speakerType[];
    updateSpeaker : (data: speakerType[]) => void;
}

export const useSpeakers = create<speakerStore>()(set => ({
    speakers : [],
    updateSpeaker : (data:speakerType[]) => set(state => ({speakers : data})),
}));


export const speakers = [
    {
      id: "speaker_1",
      name: "Dr. Jane Doe",
      bio: "Dr. Jane Doe is a seasoned researcher and professor in Computer Science. She holds a Ph.D. in Artificial Intelligence and has published numerous research papers in top-tier conferences. With a passion for technology and education, Dr. Doe is dedicated to fostering innovation and knowledge-sharing in the tech community.",
      titles: "Ph.D. in Artificial Intelligence, Professor",
      image: "/assets/speaker1.png",
      sessions: [
        {
          id: "1",
        },
        {
          id: "4",
        },
        {
          id: "6",
        },
      ],
    },
    {
      id: "speaker_2",
      name: "John Smith, M.Sc.",
      bio: "John Smith is a skilled software engineer with a Master's degree in Computer Science. With over a decade of experience in building scalable and efficient systems, he has contributed to various open-source projects. John is passionate about sharing his expertise through workshops and mentoring aspiring developers.",
      titles: "M.Sc. in Computer Science, Software Engineer",
      image: "/assets/speaker2.png",
      sessions: [
        {
          id: "2",
        },
        {
          id: "5",
        },
      ],
    },
    {
      id: "speaker_3",
      name: "Dr. Alex Rodriguez",
      bio: "Dr. Alex Rodriguez is a renowned cybersecurity expert with a Ph.D. in Information Security. He has led numerous security assessments and is a sought-after speaker at cybersecurity conferences. Dr. Rodriguez is committed to raising awareness about cybersecurity threats and best practices.",
      titles: "Ph.D. in Information Security, Cybersecurity Expert",
      image: "/assets/speaker3.png",
      sessions: [
        {
          id: "3",
        },
      ],
    },
    {
        id: "speaker_5",
        name: "David Lee, Ph.D.",
        bio: "Dr. David Lee is a recognized expert in the field of machine learning and artificial intelligence. Holding a Ph.D. in Computer Science, Dr. Lee has led pioneering research projects in natural language processing and is committed to advancing the capabilities of AI systems.",
        titles: "Ph.D. in Computer Science, AI Researcher",
        image: "/assets/speaker3.png",
        sessions: [
          {
            id: "4",
          },
        ],
      },
      {
        id: "speaker_6",
        name: "Sarah Johnson, M.Sc.",
        bio: "Sarah Johnson is a passionate advocate for diversity in tech. With a Master's degree in Software Engineering, she has contributed to initiatives promoting inclusivity in the technology industry. Sarah believes in creating a supportive environment for everyone to thrive in tech.",
        titles: "M.Sc. in Software Engineering, Diversity Advocate",
        image: "/assets/speaker1.png",
        sessions: [
          {
            id: "6",
          },
        ],
      },
      {
        id: "speaker_7",
        name: "Michael Williams",
        bio: "Michael Williams is a seasoned project manager with extensive experience in leading software development projects. He has successfully managed cross-functional teams and delivered high-impact solutions. Michael is dedicated to sharing insights on effective project management practices.",
        titles: "Project Management Professional",
        image: "/assets/speaker2.png",
        sessions: [
          {
            id: "2",
          },
          {
            id: "5",
          },
        ],
      }
    ]