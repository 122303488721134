import React from 'react'

const Logo = ({className} : {className ?: string}) => {
  return (
    <div className={`bg-white h-12 w-12 rounded p-1 ${className}`}>
      <img src="/assets/logo.svg" alt="logo" className={`h-full w-full`} />
    </div>
  )
}

export default Logo