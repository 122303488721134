import { create } from "zustand";

export interface sponsorType {
  id: string;
  name: string;
  logo: string;
  role: string;
  description: string | null;
  website: string | null;
  mobile: string | null;
}

interface sponsorStore {
    sponsors : sponsorType[];
    updateSponsor : (data: sponsorType[]) => void;
}

export const useSponsor = create<sponsorStore>()(set => ({
    sponsors : [],
    session : null,
    updateSponsor : (data:sponsorType[]) => set(state => ({sponsors : data})),
}));


export const sponsors = [
    {
      id: "sponsor_1",
      name: "TechCorp",
      logo: "/assets/logo.svg",
      description: "TechCorp is a leading technology company specializing in innovative solutions for businesses. With a focus on cutting-edge technologies, TechCorp empowers organizations to thrive in the digital era.",
      website: "https://www.techcorp.com",
    },
    {
      id: "sponsor_2",
      name: "DataTech Solutions",
      logo: "/assets/sponsor.svg",
      description: "DataTech Solutions is a data-driven consultancy providing advanced analytics and business intelligence services. Our team of experts helps businesses leverage data for strategic decision-making.",
      website: "https://www.datatechsolutions.com",
    },
    {
      id: "sponsor_3",
      name: "Cloud Innovations",
      logo: "/assets/sponsor2.svg",
      description: "Cloud Innovations is a leading cloud services provider, offering scalable and secure cloud solutions. Our mission is to empower businesses to harness the full potential of cloud technology.",
      website: "https://www.cloudinnovations.com",
    },
    {
      id: "sponsor_4",
      name: "CodeLabs",
      logo: "/assets/sponsor.svg",
      description: "CodeLabs is an education-focused organization, providing coding bootcamps and workshops for aspiring developers. Join us in shaping the future of tech talent!",
      website: "https://www.codelabs.org",
    },
    {
      id: "sponsor_5",
      name: "Infinite Innovations",
      logo: "/assets/logo.svg",
      description: "Infinite Innovations specializes in creating immersive digital experiences. Our team of creatives and technologists transforms ideas into interactive solutions that captivate audiences.",
      website: "https://www.infiniteinnovations.com",
    },
  ];
  