export const board = [
        {
            position: "President",
            name: "Sandra Ponting",
            affiliation: "San Diego State University",
            image : "/assets/president.webp",
        },
        {
            position: "Vice President",
            name: "Michelle Millar",
            affiliation: "University of San Francisco",
            image : "/assets/vp.webp",
        },
        {
            position: "Immediate Past President",
            name: "Saehya Ann",
            affiliation: "California State University, East Bay",
            image : "/assets/pp.webp",
        },
        {
            position: "Treasurer",
            name: "Shane Blum",
            affiliation: "Texas Tech University",
            image : "/assets/treasurer.webp",
        },
        {
            position: "Secretary",
            name: "Harold Lee",
            affiliation: "University of North Texas",
            image : "/assets/secretary.webp",
        },
        {
            position: "Director of Member Services",
            name: "Hyunsuk Choi",
            affiliation: "California State University, Sacramento",
            image : "/assets/dms.webp",
        },
        {
            position: "Director of Networking/Marketing",
            name: "Michelle Alcorn",
            affiliation: "Texas Tech University",
            image : "/assets/dnm.webp",
        },
        {
            position: "Director of Research",
            name: "Kelly Min",
            affiliation: "Cal Poly Pomona",
            image : "/assets/dor.webp",
        },
        {
            position: "Director of Industry Relations",
            name: "Janet Mansinne",
            affiliation: "Knowledge Matters",
            image : "/assets/doi.webp",
        },
        {
            position: "Director of Education",
            name: "Smita Singh",
            affiliation: "Metropolitan State University of Denver",
            image : "/assets/doe.webp",
        },
        {
            position: "Conference Chair",
            name: "Godwin-Charles Ogbeide",
            affiliation: "Metropolitan State University of Denver",
            image : "/assets/cc.webp",
        }
    ];