import { zodResolver } from "@hookform/resolvers/zod"
import { useForm } from "react-hook-form"
import * as z from "zod"
import { Form } from "../../components/ui/form"
import { Button } from "../../components/ui/button"
import CustomInput from "../../components/CustomInput/CustomInput"
import { useMutation, useQuery } from "react-query"
import { api, url } from "../../lib/api"
import { useUser } from "../../data/user"
import { Card, CardContent, CardFooter, CardHeader, CardTitle } from "../../components/ui/card"
import { sponsorType } from "../../data/sponsors"
import { Input } from "../../components/ui/input"
import { Label } from "../../components/ui/label"
import { useToast } from "../../components/ui/use-toast"
import { ChangeEvent, useState } from "react"
import { ButtonLoading } from "../../components/Button/CustomButton"
import {image as imageURL} from '../../lib/image'

const formSchema = z.object({
  name : z.string(),
  description : z.string().optional(),
  website : z.string().optional(),
  contact : z.string().optional(),
  role : z.string().optional()
//   image: z.unknown().optional()
})


const Sponsor = () => {
  const { token } = useUser();
  const { toast } = useToast()
  const[ image,setImage ]= useState<File | null> (null)

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema)
  })

  // 2. Define a submit handler.
  function onSubmit(values: z.infer<typeof formSchema>) {
      // Do something with the form values.
      // ✅ This will be type-safe and validated.
      console.log(values)
      mutate({image, ...values})
    }

  const { data, isSuccess, refetch } = useQuery({
      queryKey : ['sponsors'],
      queryFn : async () => {
        const res = await api.get("/api/sponsor", {
          headers : {
            Authorization : `Bearer ${token}`,
            "Content-Type": 'application/json'
          }
        });
  
        const sponsors:sponsorType[] = res.data.data
        return sponsors
      },
      enabled : (token !== null)
    });




    const handleImage = (e: ChangeEvent<HTMLInputElement>) => {
        const files = e.target.files;

        if(files){
          setImage(files[0])
        }
    }
  
    
    const { mutate, isLoading, error } = useMutation({
        mutationFn: async (data:any) => {
          const formData = new FormData();
          formData.append("image", data.image);
          formData.append("name", data.name);
          formData.append("description", data.description);
          formData.append("website", data.website);
          formData.append("contact", data.contact);
          formData.append("role", data.role);
          
          const res = await api.post("/api/sponsor", formData, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
          
          return res.data;
          
  
          return res.data;
        },
        onSuccess: () => {
          refetch();
          toast({
            title : "Sponsor created successfully!"
          })
        }
      });

      const removeItem = useMutation({
        mutationFn: async (id:string) => {
          const res = await api.delete("/api/sponsor", { 
            headers : {
              Authorization : `Bearer ${token}`,
              "Content-Type": 'application/json'
            }, 
            data : { id }
          });

          return res.data;
        },
        onSuccess: () => {
          toast({
            title: "Sponsor removed successfully!"
          });
          refetch();
        }
      });

  return (
    <div>
      
      <section className="space-y-5 bg-white rounded-lg p-4 sm:p-10 shadow">
        <p>Add sponsor</p>


        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-8">
            <CustomInput
            name="name"
            control={form.control}
            label="Name"
            />
            <CustomInput
            name="description"
            control={form.control}
            label="Description"
            />
            <CustomInput
            name="role"
            control={form.control}
            label="Role / Sponsorship"
            />
            <div className="grid grid-cols-2 gap-4">
                <CustomInput
                name="website"
                control={form.control}
                label="Website"
                />
                <CustomInput
                name="contact"
                control={form.control}
                label="Contact"
                />
            </div>  

            <div>
                <Label>Logo</Label>    
                <Input name="image" type="file" onChange={handleImage}/>      
            </div>    
            {
              isLoading ? <ButtonLoading/> : <Button type="submit">Submit</Button>
            }
          </form>
        </Form>
      </section>


      <section className="py-10 space-y-4">
        <p className="text-3xl">Sponsors</p>


        <div className="grid sm:grid-cols-3 gap-3">
          {
            isSuccess && (
              data.map(sponsor => (
                <Card className="justify-between flex flex-col">
                  {/* <CardHeader></CardHeader> */}
                  <CardContent className="pt-5 space-y-2">
                    <img src={imageURL(sponsor.logo)} alt="" className="h-16" />
                    <p className="font-bold text-lg">{sponsor.name}</p>
                    <p className="text-sm font-semibold">{sponsor.role}</p>
                    <p className="text-sm">
                        {
                            sponsor.description
                        }
                    </p>
                  </CardContent>
                  <CardFooter>
                    <Button onClick={() => removeItem.mutate(sponsor.id)} variant={"destructive"}>Delete</Button>
                  </CardFooter>
                </Card>
                ))
            )
          }
        </div>
      </section>
    </div>
  )
}

export default Sponsor