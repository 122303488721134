import React from 'react'
import SubHeader from '../../components/Headers/SubHeader'
import { AlarmClock, Calendar, HeartIcon, MapPinIcon, UserIcon } from 'lucide-react'
import moment from 'moment'
import { Button } from '../../components/ui/button'
import { useParams } from 'react-router-dom'
import { useSessions } from '../../data/sessions'
import { useMutation } from 'react-query'
import { api } from '../../lib/api'
import { useToast } from '../../components/ui/use-toast'
import { ButtonLoading } from '../../components/Button/CustomButton'
import { UserType, useUser } from '../../data/user'

const Session = () => {
  const { sessionId } = useParams();
  const { sessions } = useSessions();
  const { user, updateUser } = useUser();
  const { toast } = useToast();

  const sessionData = sessions.find((session) => session.id === sessionId)

  const { mutate, isLoading } = useMutation({
    mutationKey: "addToMySession",
    mutationFn : async (sessionId:string) => {
      const res = await api.post("/api/my-session", {sessionId});
      return res.data;
    },
    onSuccess: ({user}: {user:UserType}) => {
      updateUser(user);
      toast({title : "Session added to your schedule!"})
    }
  });

  const remove = useMutation({
    mutationFn : async (sessionId:string) => {
      const res = await api.delete("/api/my-session", { data : {sessionId}});
      return res.data;
    },
    onSuccess: ({user}: {user:UserType}) => {
      updateUser(user);
      toast({title : "Session removed from your schedule!"})
    }
  });

  const favourite = user?.sessionId.find((id) => id === sessionData?.id);

  if(!sessionData) return <div>Session not found!</div>

  const CTA = () => (
    !favourite ? (<Button onClick={() => mutate(sessionData.id)} className='gap-5 w-full shadow-xl'>
                    <HeartIcon/> Add To My Schedule
                </Button>) : (
                <Button onClick={() => remove.mutate(sessionData.id)} className='gap-5 w-full shadow-xl'>
                    <HeartIcon/> Remove From My Schedule
                </Button>)
  )
  return (
    <section className='font-figtree bg-gray-100 min-h-screen'>
      <SubHeader
      title='Session'
      text={sessionData.title}/>

      <div className='bg-white w-4/5 -mt-6 shadow-xl text-sm divide-x mx-auto rounded-lg p-4 flex justify-center gap-4'>
        <div className='flex items-center gap-2 justify-center px-2'>
          <AlarmClock className='h-5 w-5'/>
          <span>{sessionData.startTime} - {sessionData.endTime}</span>
        </div>
        <div className='flex items-center gap-2 justify-center px-2'>
          <Calendar className='h-5 w-5'/>
          <span>{moment(sessionData.date).format("Do MMM")}</span>
        </div>
      </div>

      <section className='px-5 space-y-8 py-10'>
        <div className='flex justify-between items-center flex-wrap gap-4'>
          {
            sessionData.venue && (
              <div className='flex items-center gap-4'>
                <MapPinIcon className='h-6 w-6'/> 
                {sessionData.venue}
              </div>
            )
          }
          {
          sessionData.moderator && (
            <div className='flex items-center gap-4'>
              <UserIcon className='h-6 w-6'/> 
              {sessionData.moderator}
            </div>
          )
        }
        </div>
        <div style={{whiteSpace : "pre-wrap"}} className='capitalize'>
           {sessionData.description.toLowerCase()}
        </div>     

        { 
          isLoading || remove.isLoading ? <ButtonLoading/> : <CTA/>
        } 

        {/* <div className='space-y-3'>
        <p className='font-semibold text-lg'>Moderator</p>

        <div className='space-y-4'>
          {
            sessionData.speakerId.map((speaker) => (
              speaker && (
                <div>
                  {speaker}
                </div>
              )
            ))
          }
        </div>
      </div> */}

      </section>
    </section>
  )
}

export default Session