import React, { useEffect, useState } from 'react'
import Logo from '../../components/Logo/Logo'
import BrandSlide from '../../components/Slides/BrandSlide'
import { AwardIcon, CalendarHeart, ChevronRight } from 'lucide-react'
import Category from '../../components/Cards/Category'
import SponsorSlide from '../../components/Slides/SponsorSlide'
import { Link } from 'react-router-dom'
import { useUser } from '../../data/user'
import { sessionType, useSessions } from '../../data/sessions'
import moment from 'moment'
import { Skeleton } from '../../components/ui/skeleton'
import Board from '../../components/Slides/Board'
import { BellAlertIcon } from '@heroicons/react/24/outline'
import { useNotifications } from '../../data/notification'
import {
  Carousel,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
} from "../../components/ui/carousel"
import Autoplay from 'embla-carousel-autoplay'


const Home = () => {
  const { user } = useUser();
  const { sessions } = useSessions();
  const { notifications } = useNotifications();
  const [ upcoming, setUpcoming ] = useState<sessionType[] | null >([]);
  
  useEffect(() => {
    if(sessions){
      const upcoming = sessions.filter(session => {
        const sessionDateTime = moment(`${session.date} ${session.startTime}`, 'M/D/YYYY HH:mm');
        const currentDateTime = moment();
      
        // Check if the session's date is today or in the future, and if the start time is later than the current time
        return sessionDateTime.isSameOrAfter(currentDateTime);
      }).sort((a, b) => moment(`${a.date} ${a.startTime}`, 'M/D/YYYY HH:mm').diff(moment(`${b.date} ${b.startTime}`, 'M/D/YYYY HH:mm')));
      
      upcoming.length === 0 ? setUpcoming(null) : setUpcoming(upcoming);    
    }
  }, [sessions]);

  const plugin = React.useRef(
    Autoplay({ delay: 5000, stopOnInteraction: true })
  )

  return (
    <section>
      <header className='py-5 px-2 bg-main pb-16'>
         <div className='flex justify-between text-white'>
          <div>
            <span className='text-xs font-light'>Welcome back,</span>
            <p className='font-semibold text-xl capitalize'>{user?.firstName || <Skeleton className='w-20 h-4 mt-1'/>}</p>
          </div>
          <div>
            <Logo/>
          </div>
         </div>
      </header>
      <BrandSlide/>
      <section className='space-y-8 py-5 px-3'>
        <div>
          <p className='font-semibold text-sm'>Upcoming Schedule</p>

          {
            upcoming ? (
              <Link to={`/dashboard/session/${upcoming[0]?.id}`} className='bg-white flex gap-4 items-center rounded-lg p-3'>
              <div className='bg-blue-100 flex items-center justify-center p-2 rounded-lg'>
                <CalendarHeart className='h-6 w-6 text-main'/>
              </div>
              <div className='space-y-1'>
                <p className='font-[600] font-play text-sm'>{upcoming[0] ? moment(upcoming[0]?.date + ' ' + upcoming[0].startTime, 'MM/DD/YYYY HH:mm').format("dddd, Do MMM - HH:mmA") : <Skeleton className="h-6 w-[200px]" />}</p>
                <p className='text-xs'>{upcoming[0]?.title.slice(0,50) || <Skeleton className='h-4 w-[100px]'/>}</p>
              </div>
                <ChevronRight className='h-6 ml-auto'/>
            </Link>) : (
                        <div className='bg-white flex gap-4 items-center rounded-lg p-3'>
                        <div className='bg-blue-100 flex items-center justify-center p-2 rounded-lg'>
                          <CalendarHeart className='h-6 w-6 text-main'/>
                        </div>
                        <div className='space-y-1'>
                          <p className='font-[600] font-play text-sm'>No session coming up</p>
                        </div>
                          <ChevronRight className='h-6 ml-auto'/>
                      </div>
            )
          }
        </div>

        <div>
          <p className='font-semibold text-sm'>Notifications ({notifications.length})</p>

          <Carousel plugins={[plugin.current] as any}>
            <CarouselContent>
              {
                notifications.map((notification, index) => (
                  <CarouselItem key={index}>
                    <Link to={`/dashboard/notifications/${notification.id}`} className='bg-white flex gap-4 items-center rounded-lg p-3'>
                      <div className='bg-blue-100 flex items-center justify-center p-2 rounded-lg'>
                        <BellAlertIcon className='h-6 w-6 text-main'/>
                      </div>
                      <div className='space-y-1'>
                        <p className='font-[600] font-play text-sm'>{notification.title}</p>
                        <p className='text-xs'>{notification.message.slice(0, 30)}...</p>
                      </div>
                        <ChevronRight className='h-6 ml-auto'/>
                    </Link>
                  </CarouselItem>
                ))
              }
            </CarouselContent>
          </Carousel>
        </div>


      </section>

      <section className='py-5 space-y-1'>
        <p className='font-semibold text px-3 text-sm'>Categories</p>

        <div className='grid grid-cols-2 gap-4 px-3'>
          <Link to={"/dashboard/attendees"}>
            <Category
            Icon={"/assets/user-group.svg"}
            title='Attendees'/>          
          </Link>

          <Link to={"/dashboard/speakers"}>
            <Category
            Icon={"/assets/user-speaker.svg"}
            title='Speakers'/>          
          </Link>

          <Link to={"/dashboard/gallery"}>
            <Category
            Icon={"/assets/gallery.svg"}
            title='Gallery'/>
          </Link>
          <Link to={"/dashboard/notifications"}>
            <Category
            Icon={"/assets/bell.svg"}
            title='Notifications'/>
          </Link>
        </div>
      </section>

      <section className='py-5 px-3 space-y-1'>
        <Link to={"./awards"}>
          <div className='bg-white p-5 rounded-xl flex justify-between items-center'>
            <div>
              <p className='text-xl uppercase font-[700]'>Conference Awards</p>
              <p className='text-gray-500 text-sm'>Sponsored by CSU East Bay HRT</p>
            </div>
            <div>
              <div className='p-4 rounded-xl bg-blue-100 text-blue-500'>
                <AwardIcon className='h-14 w-14'/>
              </div>
            </div>
          </div>
        </Link>

      </section>

      <section className='space-y-5 pt-5'>
        <div className='flex justify-between items-center px-3'>
          <p className='font-semibold text'>Board Members</p>
        </div>

        <Board/>
      </section>

      <section className='py-5 space-y-2.5'>
        <div className='flex justify-between items-center px-3'>
          <p className='font-semibold text'>Sponsors</p>
        </div>

        <SponsorSlide/>
      </section>

      <section>
        <img src="/assets/home.jpg" alt="well" className='w-full' />
      </section>
    </section>
  )
}

export default Home