const Sponsor = ({src, name, role} : { src : string, name : string, role ?: string}) => {
  return (
    <div className='bg-white p-5 rounded-lg flex flex-col items-center gap-3'>
        <p className='font-semibold font-play'>{role}</p>
        <img src={src} alt="sponsr" className='h-36 w-48 object-contain' />
        <p className='text font-semibold font-light'>{name}</p>
    </div>
  )
}

export default Sponsor