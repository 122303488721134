import { create } from "zustand";

export interface NotificationType {
  id: string;
  title: string;
  message: string;
  image: string | null;
  createdAt: Date;
}

interface notificationStore {
    notifications : NotificationType[];
    updateNotification : (data: NotificationType[]) => void;
}

export const useNotifications = create<notificationStore>()(set => ({
    notifications : [],
    updateNotification : (data:NotificationType[]) => set(state => ({notifications : data})),
}));


export const notifications = [
    {
      id: "1",
      title: "Event Reminder",
      content: "Don't forget to join our virtual event tomorrow at 3 PM! Exciting sessions await you.",
      createdAt: "2023-04-01T08:00:00Z",
    },
    {
      id: "2",
      title: "New Session Added",
      content: "A new session has been added to the schedule. Check it out in the app for more details.",
      createdAt: "2023-04-02T12:30:00Z",
    },
    {
      id: "3",
      title: "Speaker Update",
      content: "Meet our keynote speaker, John Doe, in the main auditorium at 2:00 PM.",
      createdAt: "2023-04-03T09:45:00Z",
    },
    {
      id: "4",
      title: "Feedback Request",
      content: "Share your thoughts! We value your feedback. Take a moment to fill out our event survey.",
      createdAt: "2023-04-04T15:20:00Z",
    },
  ];
  