import React from 'react'
import { Outlet } from 'react-router-dom'
import MenuTab from '../components/Tab/MenuTab'

const MainLayout = () => {
  return (
    <div className='bg-gray-100 min-h-screen font-figtree'>
        <Outlet/>
        <MenuTab/>
        <div className='py-10'/>
    </div>
  )
}

export default MainLayout