import moment from 'moment'
import React from 'react'
import { Link } from 'react-router-dom'

interface Props {
    title : string;
    content : string;
    date : Date;
    id : string
}

const Notification = ({title, date, content, id} : Props) => {
  return (
    <Link to={`/dashboard/notifications/${id}`}>
        <div className='bg-white rounded-2xl p-4 flex justify-between gap-2 items-start'>
        <div>
            <p className='text-lg font-[700] font-figtree'>{title}</p>
            <span className='text-light text-sm'>{content.slice(0, 40)}...</span>
        </div>
        <div>
            <span className='whitespace-nowrap text-sm'>{moment(date).format("Do MMM")}</span>
        </div>
    </div>
    </Link>
  )
}

export default Notification