import React from 'react'
import TabsHeader from '../../components/Headers/TabsHeader'
import { useNotifications } from '../../data/notification'
import Notification from '../../components/Cards/Notification'

const Notifications = () => {
  const { notifications } = useNotifications();

  return (
    <section className='bg-gray-100 min-h-screen space-y-8 font-figtree'>
        <TabsHeader
          title='Notifications'
          back/>
        
        <div className='px-5 flex flex-col gap-4'>
          {
            notifications.map((notification) => (
              <Notification
               id={notification.id}
               key={notification.id}
               title={notification.title}
               content={notification.message}
               date={notification.createdAt}/>
            ))
          }
        </div>
    </section>
  )
}

export default Notifications