import React, { useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'

const Index = () => {
    const router = useNavigate();

    useEffect(() =>{
      const token = localStorage.getItem("token");
  
      if(token){
          router("/dashboard");
      }
    }, []);

  return (
    <div className='h-screen bg-blue-100 font-figtree overflow-hidden'>
        <div className='space-y-5'>
            <img src="assets/logo.svg" alt="logo" className='h-20 object-contain mt-10 mx-auto' />
            <p className='text-center text-3xl font-semibold'>Explore The World Of Hospitality</p>
        </div>

        <section className='rounded-t-[50px] space-y-8 bg-white absolute z-20 pb-10 bottom-0 w-full px-5 pt-20'>
            <p className='font-black text-4xl text-gray-600'>Welcome to <br /><span className='text-main'>WF CHRIE</span></p>

            <p> 2024 WEST FEDERATION CHRIE CONFERENCE 
                February 1 - 3, 2024. The conference will take place on February 1-3, 2024 in Denver, Colorado. Metropolitan State University will be our host.
            </p>

            <div className='rounded-full grid grid-cols-2 h-14'>
                <Link to={'/auth/login'}>
                    <div className='bg-main flex-1 text-white flex items-center justify-center text-lg text-center rounded-full h-full'>
                        Sign In
                    </div>
                </Link>
                <Link to={'/auth/register'}>
                <div className='h-full text-main flex-1 flex items-center justify-center text-lg text-center '>
                    Register
                </div>
                </Link>
            </div>
        </section>
    </div>
  )
}

export default Index