import React, { ChangeEvent } from 'react'
import TabsHeader from '../../components/Headers/TabsHeader'
import { Button } from '../../components/ui/button'
import { useUser } from '../../data/user'
import { useNavigate } from 'react-router-dom'
import { CameraIcon } from 'lucide-react'
import { useMutation, useQueryClient } from 'react-query'
import { api, url } from '../../lib/api'
import { useToast } from '../../components/ui/use-toast'
import { image } from '../../lib/image'

const Profile = () => {
  const { user, setToken, token } = useUser();
  const router = useNavigate();
  const { toast } = useToast();

  const signOut = () => {
    window.localStorage.removeItem("token");
    setToken(null);
    router("/")
  }
  const queryClient  = useQueryClient();

  const { mutate, isLoading } = useMutation({
    mutationFn: async (file:File) => {
      const formData = new FormData();
      formData.append('image', file);
      const response = await api.post('/api/user', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'Authorization': `Bearer ${token}`
        }
      });
      return response.data;
    },
    onSuccess: () => {
      toast({title: "Image updated successfully!"});
      queryClient.refetchQueries([token], { active : true })
    }
  })

  const updateImage = (e:ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files;

    console.log(file)
    if(file){
      mutate(file[0])      
    }
  }

  return (
    <section className='bg-main flex flex-col gap-5 min-h-screen'>
      <TabsHeader
        title='Profile'/>

      <div className='-mb-20 z-20 h-48 w-48 mx-auto relative'>
        <img src={user?.image ? `${image(user.image)}` : "/assets/user.png"} alt="woman" className='rounded-full border-4 border-main h-full w-full object-cover'/>
        <div className='bg-blue-100 p-3 rounded-full absolute bottom-0 right-0 z-20'>
          <CameraIcon className='h-8 w-8'/>
          <input onChange={updateImage} type="file" accept='.jpg,.png,.svg' className='h-10 w-10 opacity-0 z-30 absolute bottom-0' />
        </div>
      </div>

      <div className='bg-gray-100 rounded-t-[50px] w-full pt-20 flex-1 px-3 space-y-5'>
        <p className='font-semibold text-lg'>Personal Information</p>

        <div className='space-y-2.5'>
            <div className='bg-white flex justify-between items-center p-4 rounded-xl gap-10'>
              <span className='text-sm'>First Name</span>
              <span className='font-[600] text-right'>{user?.firstName}</span>
            </div>
            <div className='bg-white flex justify-between items-center p-4 rounded-xl gap-10'>
              <span className='text-sm'>Last Name</span>
              <span className='font-[600] text-right'>{user?.lastName}</span>
            </div>
            <div className='bg-white flex justify-between items-center p-4 rounded-xl gap-10'>
              <span className='text-sm'>Email</span>
              <span className='font-[600] text-right'>{user?.email}</span>
            </div>
            <div className='bg-white flex justify-between items-center p-4 rounded-xl gap-10'>
              <span className='text-sm'>Mobile</span>
              <span className='font-[600] text-right'>N/A</span>
            </div>
            <div className='bg-white flex justify-between items-center p-4 rounded-xl gap-10'>
              <span className='text-sm'>Location</span>
              <span className='font-[600] text-right'>N/A</span>
            </div>
        </div>

        <div className='space-y-3'>
          {/* <Button className='w-full shadow-lg'>
            Edit Profile
          </Button> */}
          <Button onClick={signOut} className='w-full shadow-lg' variant={"destructive"}>
            Sign Out
          </Button>
        </div>
      </div>
    </section>
  )
}

export default Profile