import { ChevronRight } from 'lucide-react'
import React from 'react'

interface Props {
    firstName : string;
    lastName : string;
    email : string;
    mobile ?: string;
    image ?: string;
}

const Attendee = ({firstName, lastName, email} : Props) => {
  return (
    <div className='rounded-2xl bg-white p-4 grid grid-cols-12 gap-5'>
         <div className="col-span-2 bg-blue-300 text-main rounded-2xl  flex justify-center items-center">
            <span>{firstName.split("")[0].toUpperCase()}</span>
         </div>
         <div className="col-span-8">
            <p className='capitalize'>{firstName} {lastName}</p>
            <span className='text-xs'>{email}</span>
         </div>
    </div>
  )
}

export default Attendee