import { zodResolver } from "@hookform/resolvers/zod"
import { useForm } from "react-hook-form"
import * as z from "zod"
import { Form } from "../../components/ui/form"
import { Button } from "../../components/ui/button"
import CustomInput from "../../components/CustomInput/CustomInput"
import { useMutation, useQuery } from "react-query"
import { api } from "../../lib/api"
import { useUser } from "../../data/user"
import { NotificationType } from "../../data/notification"
import { Card, CardContent, CardFooter, CardHeader, CardTitle } from "../../components/ui/card"
import { useToast } from "../../components/ui/use-toast"
import { ButtonLoading } from "../../components/Button/CustomButton"

const formSchema = z.object({
  title : z.string(),
  message : z.string()
})


const Notification = () => {
  const { token } = useUser();

  const { toast } = useToast();

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema)
  })

  // 2. Define a submit handler.
  function onSubmit(values: z.infer<typeof formSchema>) {
      // Do something with the form values.
      // ✅ This will be type-safe and validated.
      mutate(values)
    }

  const { data, isSuccess, refetch } = useQuery({
      queryKey : ['notifications'],
      queryFn : async () => {
        const res = await api.get("/api/notification", {
          headers : {
            Authorization : `Bearer ${token}`,
            "Content-Type": 'application/json'
          }
        });
  
        const notifications:NotificationType[] = res.data.data
        return notifications
      },
      enabled : (token !== null)
    });

    const { mutate, isLoading, error } = useMutation({
      mutationFn: async (data:z.infer<typeof formSchema>) => {
       const res = await api.post("/api/notification", data, {
          headers : {
            Authorization : `Bearer ${token}`,
            "Content-Type": 'application/json'
          }
        });

        return res.data;
      },
      onSuccess: () => {
        refetch();
        toast({
          title : "Notification created successfully!"
        })
      }
    });

    const removeItem = useMutation({
      mutationFn: async (id:string) => {
        const res = await api.delete("/api/notification", { 
          headers : {
            Authorization : `Bearer ${token}`,
            "Content-Type": 'application/json'
          }, 
          data : { id }
        });
  
        return res.data;
      },
      onSuccess: () => {
        toast({
          title: "Notification removed successfully!"
        });
        refetch();
      }
    });
  
  return (
    <div>
      
      <section className="space-y-5 p-4 sm:p-10 border rounded-xl">
        <p>Add Notification</p>


        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-8">
            <CustomInput
            name="title"
            control={form.control}
            label="Title"
            />
            <CustomInput
            name="message"
            control={form.control}
            label="Message Content"
            textarea
            />
            {
              isLoading ? <ButtonLoading/> : <Button type="submit">Submit</Button>
            }
          </form>
        </Form>
      </section>


      <section className="py-10 space-y-4">
        <p className="text-3xl">Notifications</p>


        <div className="grid sm:grid-cols-3">
          {
            isSuccess && (
              data.map(notification => (
                <Card>
                  <CardHeader>
                    <CardTitle>{notification.title}</CardTitle>
                  </CardHeader>
                  <CardContent>
                    {
                      notification.message
                    }
                  </CardContent>
                  <CardFooter>
                    <Button onClick={() => {
                    const sure  = window.confirm("Are you sure you want to delete this?")
                    sure && removeItem.mutate(notification.id)
                    }}
                    variant={"destructive"}>Delete</Button>
                  </CardFooter>
                </Card>
                ))
            )
          }
        </div>
      </section>
    </div>
  )
}

export default Notification