import React, { useEffect, useState } from 'react'
import { Outlet, useNavigate } from 'react-router-dom'
import { useUser } from '../data/user';

const Auth = () => {
  const router = useNavigate();
  const {token} = useUser();
  
  // Redirect logic
  useEffect(() => {
    token && router("/dashboard");
  }, [token]);

  return (
    <div><Outlet/></div>
  )
}

export default Auth