import React, { useEffect, useState, useTransition } from 'react'
import TabsHeader from '../../components/Headers/TabsHeader'
import DateSlider from '../../components/Dates/DateSlider'
import Session from '../../components/Cards/Session'
import { sessionType, useSessions } from '../../data/sessions'
import moment from 'moment'
import { sortStartTimes } from '../../lib/sort'
import { Skeleton } from '../../components/ui/skeleton'

const Schedule = () => {
  const { sessions, loading, currentDate, setCurrentDate} = useSessions();
  const [ currentSessions, setCurrentSessions ] = useState<sessionType[]>([]);
  const [ startTime, setStartTimes ] = useState<string[]>([]);
  const [ dates, setDates ] = useState<Date[]>([]);

  useEffect(() => {
    if(sessions){
      const filterSessions = sessions.filter(({date}) => moment(date).date() === currentDate)
      setCurrentSessions(filterSessions);

      const sortedDates = sessions.sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime())
      .map(({date}) => date);
      setDates([...new Set(sortedDates)]);

      let times = filterSessions.map(({startTime}) => startTime)
      times = times.sort(sortStartTimes);
      setStartTimes([...new Set(times)]);      
    }
  }, [currentDate, sessions]);

  return (
    <section>
      <TabsHeader
      title='Schedule'/>

      <section className='py- space-y-5'>
        <DateSlider dates={dates} selectedDate={currentDate} setSelectedDate={setCurrentDate}/>

        {
          startTime.map(time => (
            <div key={time} className='px-3 space-y-4'>
              <p className='py-4 border-b'>{time}</p>

              <div className='flex flex-col gap-4'>
                {
                  currentSessions.filter(({startTime, date}) => startTime === time).map(session => (
                    <Session
                      id={session.id}
                      key={session.id}
                      href={`/dashboard/session/${session.id}`}
                      title={session.title}
                      description={session.description}
                      venue={session.venue}
                      moderator={session.moderator}
                      sponsor={session.sponsor}
                      color={session.color}/>
                  ))
                }
              </div>
            </div>            
          ))
        }
        {
          loading && (
            <div className='px-3 space-y-4 py-5'>
              <Skeleton className='h-4 w-8'/>

              <div className='flex flex-col gap-4'>
                {
                  Array(5).fill("").map((i, session) => (
                    <SessionSkeleton key={session}/>
                  ))
                }
              </div>
            </div>
          )
        }

      </section>

    </section>
  )
}

export default Schedule


export const SessionSkeleton = () => (
      <div className='bg-white rounded-2xl py-3 px-4 flex gap-5 items-center'>
        <div className={`h-3 w-3 flex-shrink-0 rounded-full bg-gray-500`}></div>

        <div className='flex flex-col gap-1'>
          <Skeleton className='h-[18px] w-[200px]'/>
          <Skeleton className='h-[10px] w-[80px]'/>
        </div>

        <Skeleton className='h-4 w-4 ml-auto'/>
      </div>
)