import * as React from 'react'
import { 
    Carousel,
    CarouselContent,
    CarouselItem
} from '../ui/carousel'
import { useQuery } from 'react-query'
import { api, url } from '../../lib/api'
import { Skeleton } from '../ui/skeleton'
import Autoplay from 'embla-carousel-autoplay'
import { useUser } from '../../data/user'
import { image } from '../../lib/image'

const BrandSlide = () => {
    const { token } = useUser();

    const { isLoading, data = [] } = useQuery({
        queryFn: async () => {
            const res = await api.get("/api/banners", {
                headers : {
                  Authorization : `Bearer ${token}`,
                  "Content-Type": 'application/json'
                }
              });
            return res.data;
        },
        enabled: token !== null
    });

    const plugin = React.useRef(
        Autoplay({ delay: 2000, stopOnInteraction: true })
      )
     

  return (
    <Carousel 
    plugins={[plugin.current] as any}
    className='-mt-14'>
        <CarouselContent className='p-5'>
            {
                data && data.map((data: { image:string, link:string }, index:number) => (
                    <CarouselItem key={index} className='relative'>
                        <img src={image(data.image)} alt=""  className='h-40 z-20 object-contain bg-white rounded-lg shadow w-full'/>
                    </CarouselItem>
                ))
            }
            {
                isLoading && (
                    Array(4).fill("").map((item, i) => (
                        <CarouselItem key={i}>
                            <Skeleton className='h-40 w-full'/>
                        </CarouselItem>
                    ))
                )
            }
        </CarouselContent>
    </Carousel>
  )
}

export default BrandSlide