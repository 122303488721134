// import { sessions } from '../../data/sessions'
import moment from 'moment';
import { cn } from '../../lib/utils';
import { 
    Carousel,
    CarouselContent,
    CarouselItem
} from '../ui/carousel'
import { useSessions } from '../../data/sessions';
import { Skeleton } from '../ui/skeleton';
import React from 'react';

const DateSlider = ({dates, selectedDate, setSelectedDate}: { dates : Date[], selectedDate : number, setSelectedDate : any}) => {
    const { loading } = useSessions();
    const index = dates.map((date) => moment(date).date()).indexOf(selectedDate);

    if(loading){
        return (
        <div className='flex gap-8 justify-center'>
            {
                Array(5).fill("").map((i, ndex) => (
                    <Skeleton key={ndex} className='h-[50px] w-[50px] bg-gray-200 flex-shrink-0'/>
                ))
            }
        </div>
        )
    }

    return (
        <Carousel opts={{startIndex : index}} className='border-b py-4'>
            <CarouselContent className='px-5 justify-center'>
            {
                dates.map((date, index) => (
                    <CarouselItem key={index} onClick={() => setSelectedDate(moment(date).date())} className={cn('p-2 gap-0.5 rounded-xl flex flex-col items-center basis-1/5', {
                        'bg-blue-100 text-blue-500' : moment(date).date() === selectedDate
                    })}>
                        <span className='font-inter font-bold text-xl'>{moment(date).format("DD")}</span>
                        <span className='text-sm'>{moment(date).format("ddd")}</span>
                        <span className={cn('bg-blue-500 p-1 rounded-full', {'hidden' : moment(date).date() !== selectedDate})}/>
                    </CarouselItem>
                ))
            }
            </CarouselContent>
        </Carousel>
  )
}

export default DateSlider