import React from 'react'
import SubHeader from '../../../components/Headers/SubHeader'
import { InfoCircledIcon } from '@radix-ui/react-icons'
import { useNotifications } from '../../../data/notification'
import { useParams } from 'react-router-dom'
import { Clock } from 'lucide-react'
import moment from 'moment'

const Notification = () => {
  const { notificationId } = useParams();
  const { notifications } = useNotifications();

  const notification = notifications.find(({id}) => id === notificationId);

  return (
    <section className='font-figtree min-h-screen bg-gray-100'>
      <SubHeader
      title='Notification'
      text={notification?.title || ""}/>

      <div className='bg-white w-2/3 flex gap-4 items-center justify-center mx-auto shadow-xl -mt-8 p-4 rounded-lg'>
        <Clock className='h-5 w-5'/>
        { moment(notification?.createdAt).format("HH:mmA - Do MMM")}
      </div>

      <div className='px-5 py-10'>
        {notification?.message}
      </div>
    </section>
  )
}

export default Notification