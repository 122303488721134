import { create } from "zustand";

export interface UserType {
    id: string;
    firstName: string;
    lastName: string;
    email: string;
    image: string | null;
    sessionId: string[];
}

interface UserStore {
    user : UserType | null,
    updateUser : (data: UserType) => void,
    token : string | null,
    setToken : (string:string|null) => void
}

export const useUser = create<UserStore>()(set => ({
    user : null,
    token : null,
    updateUser : (data) => set(state => ({ user : data})),
    setToken : (data) => set(state => ({ token : data}))
}));