const Category = ({Icon, title} : {Icon : any, title : string}) => {
  return (
    <div className='bg-white p-4 rounded-lg flex flex-col items-center gap-2'>
        <div className='bg-blue-100 rounded flex items-center justify-center p-3'>
            <img src={Icon} alt="img" className='h-10 w-10' />
        </div>
        <p className='text-sm'>{title}</p>
    </div>
  )
}

export default Category