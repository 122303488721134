import * as React from "react";
import {
    Drawer,
    DrawerClose,
    DrawerContent,
    DrawerDescription,
    DrawerFooter,
    DrawerHeader,
    DrawerTitle,
    DrawerTrigger,
  } from "./components/ui/drawer";
import { Button } from "./components/ui/button";
  
// Taken from: https://gist.github.com/rikukissa/cb291a4a82caa670d2e0547c520eae53
export function useAddToHomescreenPrompt() {
  const [prompt, setState] = React.useState<any>(null);

  const promptToInstall = () => {
    if (prompt) {
      return prompt.prompt();
    }
    return Promise.reject(
      new Error(
        'Tried installing before browser sent "beforeinstallprompt" event'
      )
    );
  };

  React.useEffect(() => {
    const ready = (e:any) => {
      e.preventDefault();
      setState(e);
    };

    window.addEventListener("beforeinstallprompt", ready);

    return () => {
      window.removeEventListener("beforeinstallprompt", ready);
    };
  }, []);

  return [prompt, promptToInstall];
}

export const AddToHomeScreenDialog = () => {
  const [ promptToInstall ] = useAddToHomescreenPrompt();
  const [appNotInstalled, setAppNotInstalled] = React.useState(true);
  const [showDialog, setShowDialogue] = React.useState(false);

  // Check if the PWA is in standalone mode (installed)
  // React.useEffect(() => {
  //   const checkInstallStatus = () => {
  //     let isStandalone: boolean;

  //     if ('standalone' in window.navigator) {
  //       isStandalone = (window.navigator as any).standalone;
  //     } else {
  //       isStandalone = window.matchMedia('(display-mode: standalone)').matches;
  //     }

  //     console.log(isStandalone)

  //     setAppNotInstalled(!isStandalone);
  //     !isStandalone && setShowDialogue(true);

  //     // If the app is not installed and the prompt hasn't been displayed before
  //     if (!isStandalone && !sessionStorage.getItem('installPromptDisplayed')) {
  //       // Store the fact that the prompt has been displayed in session storage
  //       sessionStorage.setItem('installPromptDisplayed', 'true');
  //     }
  //   };

  //   checkInstallStatus();
  // }, []);


  console.log(appNotInstalled)

    return(
        <Drawer open={showDialog} onOpenChange={setShowDialogue}>
        <DrawerContent>
            <DrawerHeader>
            <DrawerTitle>Create App Shortcut On Your Home Screen</DrawerTitle>
            <DrawerDescription>Click the button to install this app on your mobile for easy access.</DrawerDescription>
            </DrawerHeader>
            <DrawerFooter>
            <Button onClick={promptToInstall}>Add To Home Screen</Button>
            <DrawerClose>
                <Button variant="outline">Cancel</Button>
            </DrawerClose>
            </DrawerFooter>
        </DrawerContent>
        </Drawer>
    )
}