import React, { useState } from 'react'
import { Card, CardHeader, CardDescription, CardContent, CardFooter, CardTitle } from '../../components/ui/card'
import { useMutation, useQuery } from 'react-query';
import { useUser } from '../../data/user';
import { api } from '../../lib/api';
import { sessionType } from '../../data/sessions';
import { MapPinIcon } from 'lucide-react';
import moment from 'moment';
import { Button } from '../../components/ui/button';
import { zodResolver } from "@hookform/resolvers/zod"
import { useForm } from "react-hook-form"
import * as z from "zod"
import { Form } from "../../components/ui/form"
import CustomInput from "../../components/CustomInput/CustomInput"
import { useToast } from '../../components/ui/use-toast';
import { ButtonLoading } from '../../components/Button/CustomButton';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "../../components/ui/dialog";


const formSchema = z.object({
  title : z.string(),
  description : z.string().optional(),
  venue : z.string().optional(),
  startTime : z.string(),
  endTime : z.string(),
  date : z.string(),
  sponsor : z.string().optional(),
})


const Sessions = () => {
  const { token } = useUser();
  const { toast } = useToast();
  const [ open, setOpen ] = useState(false);
  const [ selectedId, setSelectedId ] = useState<null | sessionType>(null)

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
  })


  // 2. Define a submit handler.
  function onSubmit(values: z.infer<typeof formSchema>) {
      mutate(values)
    }

    function onEdit(values: z.infer<typeof formSchema>) {
      editPost.mutate(values)
    }

  const { data, isSuccess, refetch } = useQuery({
    queryKey : ['sessions'],
    queryFn : async () => {
      const res = await api.get("/api/session", {
        headers : {
          Authorization : `Bearer ${token}`,
          "Content-Type": 'application/json'
        }
      });

      const sessions:sessionType[] = res.data
      return sessions
    },
    enabled : (token !== null)
  });

  const { mutate, isLoading } = useMutation({
    mutationFn: async (data:z.infer<typeof formSchema>) => {
     const res = await api.post("/api/session", data, {
        headers : {
          Authorization : `Bearer ${token}`,
          "Content-Type": 'application/json'
        }
      });

      return res.data;
    },
    onSuccess: () => {
      refetch();
      toast({
        title : "Session added successfully!"
      })
    }
  });

  const editPost = useMutation({
    mutationFn: async (data:z.infer<typeof formSchema>) => {
      console.log(data);
     const res = await api.put("/api/session", {id : selectedId?.id, ...data}, {
        headers : {
          Authorization : `Bearer ${token}`,
          "Content-Type": 'application/json'
        }
      });

      return res.data;
    },
    onSuccess: () => {
      refetch();
      toast({
        title : "Session updated successfully!"
      })
    }
  });

  const removeItem = useMutation({
    mutationFn: async (id:string) => {
      const res = await api.delete("/api/session", { 
        headers : {
          Authorization : `Bearer ${token}`,
          "Content-Type": 'application/json'
        }, 
        data : { id }
      });

      return res.data;
    },
    onSuccess: () => {
      toast({
        title: "Session removed successfully!"
      });
      refetch();
    }
  });

  
  return (
    <div className='space-y-10'>
      
      <section className="space-y-5 border p-5 rounded">
        <p>Add New Session</p>


        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-8">
            <CustomInput
            name="title"
            control={form.control}
            label="Title"
            />
            <div className='grid grid-cols-3 gap-4'>
              <CustomInput
                name='date'
                label='Date'
                type='date'
                control={form.control}
              />
              <CustomInput
                name='startTime'
                label='Starting Time'
                type='time'
                control={form.control}
              />
              <CustomInput
                name='endTime'
                label='End Time'
                type='time'
                control={form.control}
              />
            </div>
            <CustomInput
            name="venue"
            control={form.control}
            label="Venue"
            />
            <CustomInput
            name="sponsor"
            control={form.control}
            label="Sponsor"
            />
            <CustomInput
            name="description"
            control={form.control}
            label="Description"
            />
            {
              isLoading ? <ButtonLoading/> : <Button type="submit">Submit</Button>
            }
          </form>
        </Form>
      </section>
      <div>

      </div>

      <div className='grid grid-cols-1 md:grid-cols-3 gap-5'>
        {
          isSuccess && (
            data.map((session, index) => (
              <Card key={index} className='flex flex-col justify-between'>
              <CardHeader>
                <CardTitle>{session.title}</CardTitle>
                <CardDescription>{moment(session.date).format("DD-MM-YYYY")} {session.startTime} - {session.endTime}</CardDescription>
              </CardHeader>
              <CardContent className='space-y-5'>
                <p className='capitalize'>{session.description ? session.description.toLowerCase().slice(0, 40) : "No description"}</p>
                <p className='flex gap-2 items-center'><MapPinIcon className='h-4 w-4'/>{session.venue || "Unavailable"}</p>
              </CardContent>
              <CardFooter>
                <div className='space-x-5'>
                  <Button onClick={() => {setSelectedId(session); setOpen(true);
                    form.setValue("title", session.title );
                    form.setValue("description", session.description || "" );
                    form.setValue("venue", session.venue || "" );
                    form.setValue("startTime", session.startTime);
                    form.setValue("endTime", session.endTime);
                    form.setValue("sponsor", session.sponsor || "");
                  }} >
                    Edit
                  </Button>
                  <Button onClick={() => {
                    const sure  = window.confirm("Are you sure you want to delete this?")
                    sure && removeItem.mutate(session.id)
                    }} 
                    variant={"destructive"}>
                    Delete
                  </Button>
                </div>
              </CardFooter>
            </Card>
            ))
          )
        }
      </div>
      <EditModal
        open={open}
        setOpen={setOpen}
        session={selectedId}
        form={form}
        isLoading={editPost.isLoading}
        onSubmit={onEdit}/>
    </div>
  )
}

const EditModal = ({ open, setOpen, session, form, onSubmit, isLoading} : { open : boolean, setOpen : any, session : sessionType | null, form : any, onSubmit : any, isLoading : boolean}) => {
  return(
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogContent className='h-[80vh] overflow-y-auto'>
        <DialogHeader>
          <DialogTitle>Edit Session</DialogTitle>
          <DialogDescription>
          <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-8">
            <CustomInput
            name="title"
            control={form.control}
            label="Title"
            />
            <div className='grid grid-cols-3 gap-4'>
              <CustomInput
                name='date'
                label='Date'
                type='date'
                control={form.control}
              />
              <CustomInput
                name='startTime'
                label='Starting Time'
                type='time'
                control={form.control}
              />
              <CustomInput
                name='endTime'
                label='End Time'
                type='time'
                control={form.control}
              />
            </div>
            <CustomInput
            name="venue"
            control={form.control}
            label="Venue"
            />
            <CustomInput
            name="sponsor"
            control={form.control}
            label="Sponsor"
            />
            <CustomInput
            name="description"
            textarea
            control={form.control}
            label="Description"
            />
            {
              isLoading ? <ButtonLoading/> : <Button type="submit">Submit</Button>
            }
          </form>
        </Form>
          </DialogDescription>
        </DialogHeader>
      </DialogContent>
    </Dialog>
  )
}

export default Sessions



