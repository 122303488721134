import { MagnifyingGlassIcon } from '@radix-ui/react-icons'
import React from 'react'

const Search = () => {
  return (
    <div className='bg-white rounded-[13px] p-4 flex items-center gap-4'>
        <input type="text" placeholder='Search by name, email...' className='bg-gray-100 py-2 px-4 rounded-[10px] flex-1' />
        <MagnifyingGlassIcon className='h-8 w-8'/>
    </div>
  )
}

export default Search