import { zodResolver } from "@hookform/resolvers/zod"
import { useForm } from "react-hook-form"
import * as z from "zod"
import { Form } from "../../components/ui/form"
import { Button } from "../../components/ui/button"
import CustomInput from "../../components/CustomInput/CustomInput"
import { useMutation, useQuery } from "react-query"
import { api, url } from "../../lib/api"
import { useUser } from "../../data/user"
import { Card, CardContent, CardFooter, CardHeader, CardTitle } from "../../components/ui/card"
import { sponsorType } from "../../data/sponsors"
import { Input } from "../../components/ui/input"
import { Label } from "../../components/ui/label"
import { useToast } from "../../components/ui/use-toast"
import { ChangeEvent, useState } from "react"
import { ButtonLoading } from "../../components/Button/CustomButton"
import {image as imageURL} from '../../lib/image'

const formSchema = z.object({
  name : z.string(),
  bio : z.string().optional(),
  title : z.string().optional(),
//   image: z.unknown().optional()
})


const Speaker = () => {
  const { token } = useUser();
  const { toast } = useToast()
  const[ image,setImage ]= useState<File | null> (null)

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema)
  })

  // 2. Define a submit handler.
  function onSubmit(values: z.infer<typeof formSchema>) {
      // Do something with the form values.
      // ✅ This will be type-safe and validated.
      console.log(values)
      mutate({image, ...values})
    }

//   const { data, isSuccess, refetch } = useQuery({
//       queryKey : ['speaker'],
//       queryFn : async () => {
//         const res = await api.get("/api/sponsor", {
//           headers : {
//             Authorization : `Bearer ${token}`,
//             "Content-Type": 'application/json'
//           }
//         });
  
//         const sponsors:sponsorType[] = res.data.data
//         return sponsors
//       },
//       enabled : (token !== null)
//     });




    const handleImage = (e: ChangeEvent<HTMLInputElement>) => {
        const files = e.target.files;

        if(files){
          setImage(files[0])
        }
    }
  
    
    const { mutate, isLoading, error } = useMutation({
        mutationFn: async (data:any) => {
          const formData = new FormData();
          formData.append("image", data.image);
          formData.append("name", data.name);
          formData.append("bio", data.bio);
          formData.append("title", data.title);
          
          const res = await api.post("/api/speaker", formData, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
          
          return res.data;
          
  
          return res.data;
        },
        onSuccess: () => {
          toast({
            title : "Speaker created successfully!"
          })
        }
      });

    //   const removeItem = useMutation({
    //     mutationFn: async (id:string) => {
    //       const res = await api.delete("/api/sponsor", { 
    //         headers : {
    //           Authorization : `Bearer ${token}`,
    //           "Content-Type": 'application/json'
    //         }, 
    //         data : { id }
    //       });

    //       return res.data;
    //     },
    //     onSuccess: () => {
    //       toast({
    //         title: "Sponsor removed successfully!"
    //       });
    //       refetch();
    //     }
    //   });

  return (
    <div>
      
      <section className="space-y-5 bg-white rounded-lg p-4 sm:p-10 shadow">
        <p>Add sponsor</p>


        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-8">
            <CustomInput
            name="name"
            control={form.control}
            label="Name"
            />
            <CustomInput
            name="bio"
            control={form.control}
            label="Description"
            />
            <CustomInput
            name="title"
            control={form.control}
            label="Title"
            />

            <div>
                <Label>Logo</Label>    
                <Input name="image" type="file" onChange={handleImage}/>      
            </div>    
            {
              isLoading ? <ButtonLoading/> : <Button type="submit">Submit</Button>
            }
          </form>
        </Form>
      </section>
    </div>
  )
}

export default Speaker