import { cn } from '../../lib/utils'
import BackButton from '../Nav/BackButton'


const SubHeader = ({title, text, extend} : {title : string, text ?: string, extend ?: boolean}) => {
    return (
      <div className={cn('bg-main px-5 py-5 space-y-5 h-56')}>
        <div className='grid grid-cols-3 py-4 text-white bg-main sticky top-0'>
            <BackButton/>
            <span className='flex items-center justify-center capitalize'>{title}</span>
            <span></span>
        </div>
        <div className='text-center'>
            <p className='text-2xl font-play text-white capitalize'>{text}</p>
        </div>
      </div>
    )
  }

export default SubHeader